<template>
  <div v-if="order && renderComponent" :class="$style.order">
    <div :class="$style.order__title">
      <div :class="$style.order__title__header">Карточка заказа № {{ order.id }}</div>
      <div :class="$style.order__title__status">
        <span>{{ order.status ? order.status.name : 'Не заполнено' }}</span>
      </div>
      <div v-if="order.isTrial" :class="$style.order__title__trial">
        <span>Пробное</span>
      </div>
      <div :class="$style.order__title__name">
        <span>{{ order.type ? order.type.name : 'Не заполнено' }}</span>
      </div>
      <div
        v-if="order.status && order.status.name === 'Закрытый'"
        title="Длительность занятия"
        :class="$style.order__title__status"
      >
        <span>{{ getDuration() }}</span>
      </div>
      <div v-if="order.statusId === 5" :class="$style.order__title__status">
        <span>
          Причина отмены занятия:
          {{ order.cancellationReason ? order.cancellationReason.name : 'Не заполнена' }}</span
        >
      </div>

      <div
        v-if="order.statusId === 4 && order.earlyCompletingReasonId"
        :class="$style.order__title__status"
      >
        <span
          >Причина досрочного завершения занятия:
          {{
            completingReasons.find((e) => e.id === order.earlyCompletingReasonId).name ??
            'Не заполнена'
          }}</span
        >
      </div>
    </div>
    <div :class="$style.order__block">
      <div :class="$style.order__block__content">
        <div :class="$style.order__block__content__title">
          <div :class="$style.order__block__content__title__block">
            <div :class="$style.order__block__content__title__block__text">Создан</div>
            <div
              :class="[
                $style.order__block__content__title__block__input,
                $style.order__block__content__title__block__input__mr
              ]"
            >
              <div
                :class="[
                  $style.order__block__content__title__block__input__text,
                  $style.order__block__content__title__block__input__text__mr
                ]"
              >
                {{ moment(order.createdAt).format('DD.MM.YYYY') + ' ' }}
              </div>
              <div :class="$style.order__block__content__title__block__input__text">
                {{ moment(order.createdAt).format('HH:mm:ss') }}
              </div>
            </div>
          </div>
          <div :class="$style.order__block__content__title__block">
            <div :class="$style.order__block__content__title__block__text">Отменен</div>
            <div :class="$style.order__block__content__title__block__input">
              <div
                :class="[
                  $style.order__block__content__title__block__input__text,
                  $style.order__block__content__title__block__input__text__mr
                ]"
              >
                {{ order.statusId === 5 ? moment(order.updatedAt).format('DD.MM.YYYY') : '' }}
              </div>
              <div :class="$style.order__block__content__title__block__input__text">
                {{ order.statusId === 5 ? moment(order.updatedAt).format('HH:mm:ss') : '' }}
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.order__block__content__block">
          <div :class="$style.order__block__content__block__first">
            <div :class="$style.order__block__content__block__first__datetime">
              <p :class="$style.order__block__content__block__first__datetime__text">
                Время занятия
              </p>
              <date-picker
                v-model="datetime"
                valueType="format"
                type="datetime"
                :lang="lang"
                placeholer="Дата и время"
                :disabled-date="disabledDates"
                :class="$style.order__block__content__block__first__datetime__input"
              />
            </div>
            <div :class="$style.order__block__content__block__first__duration">
              <p :class="$style.order__block__content__block__first__duration__text">
                Длительность
              </p>
              <CustomInput
                v-model="order.duration.name"
                :readonly="true"
                :class="$style.order__block__content__block__first__duration__input"
              />
            </div>
            <div
              v-if="isSchoolOrder"
              :class="$style.order__block__content__block__first__class"
            >
              <p :class="$style.order__block__content__block__first__class__text">Класс</p>
              <CustomInput
                v-model="getStudentInfo"
                :readonly="true"
                :class="$style.order__block__content__block__first__class__input"
              />
            </div>
            <div
              v-if="isLogopedistOrder"
              :class="$style.order__block__content__block__first__class"
            >
              <p :class="$style.order__block__content__block__first__class__text">
                Возрастная категория
              </p>
              <CustomInput
                v-model="childrenAgeGroupName"
                :readonly="true"
                :class="$style.order__block__content__block__first__class__input"
              />
            </div>
          </div>
          <div :class="$style.order__block__content__block__second">
            <div
              v-if="isSchoolOrder"
              :class="$style.order__block__content__block__second__subjects"
            >
              <p :class="$style.order__block__content__block__second__subjects__text">
                Предмет
              </p>
              <CustomInput
                v-model="order.subject.name"
                :readonly="true"
                :class="$style.order__block__content__block__second__subjects__input"
              />
            </div>
            <div
              v-if="isLogopedistOrder"
              :class="$style.order__block__content__block__second__subjects"
            >
              <p :class="$style.order__block__content__block__second__subjects__text">
                Предмет
              </p>
              <CustomInput
                v-model="logopedSubject"
                :readonly="true"
                :class="$style.order__block__content__block__second__subjects__input"
              />
            </div>
            <div
              v-if="isSchoolOrder"
              :class="$style.order__block__content__block__second__status"
            >
              <p :class="$style.order__block__content__block__second__status__text">
                Тип подготовки
              </p>
              <CustomInput
                v-model="order.attributes.lessonType.name"
                :readonly="true"
                :class="$style.order__block__content__block__second__status__input"
              />
            </div>
          </div>
          <div v-if="isLogopedistOrder" :class="$style.order__block__content__block__three">
            <div>
              <p :class="$style.order__block__content__block__three__subjects__text">
                Речевые нарушения
              </p>
              <div :class="$style.order__block__content__block__three__subjects__block">
                <div
                  v-for="(item, index) in logopedisticAttributes.speechDisordersRelations"
                  :key="index"
                >
                  {{
                    item?.customName
                      ? subString(item?.customName)
                      : subString(item?.speechDisorder?.name)
                  }}
                </div>
              </div>
            </div>
            <div>
              <p :class="$style.order__block__content__block__three__subjects__text">
                Заключения специалиста
              </p>
              <div :class="$style.order__block__content__block__three__subjects__block">
                <div
                  v-for="(item, index) in logopedisticAttributes.expertConclusionsRelations"
                  :key="index"
                >
                  {{
                    item?.customName
                      ? subString(item?.customName)
                      : subString(item?.expertConclusion?.name)
                  }}
                </div>
              </div>
            </div>
            <div v-if="writtenMediaFile">
              <p :class="$style.order__block__content__block__three__subjects__text">
                Прикрепленные файлы
              </p>
              <div
                @click="downloadFile"
                :class="$style.order__block__content__block__three__subjects__block"
                style="
                  color: rgba(107, 83, 221, 1);
                  text-decoration: underline;
                  display: flex;
                  cursor: pointer;
                "
              >
                <img
                  src="@/assets/icons/format-pdf.svg"
                  style="display: flex; margin-right: 0.25rem"
                />{{ pdfFileName }}
              </div>
            </div>
          </div>
          <div :class="$style.order__block__content__block__comment">
            <div :class="$style.order__block__content__block__comment__block">
              <p :class="$style.order__block__content__block__comment__block__text">
                Комментарий к заказу
              </p>
              <CustomTextarea
                :class="$style.order__block__content__block__comment__block__input"
                v-model="order.comment"
                :readonly="true"
              />
            </div>
            <div
              v-if="isLogopedistOrder"
              :class="$style.order__block__content__block__comment__block"
            >
              <p :class="$style.order__block__content__block__comment__block__text">
                Вердикт логопеда
              </p>
              <CustomTextarea
                v-model="logopedistVerdict"
                :readonly="true"
                :class="$style.order__block__content__block__comment__block__input"
              />
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.order__block__modal">
        <div :class="$style.order__block__modal__block" v-if="order.customer">
          <div :class="$style.order__block__modal__block__time">
            {{
              new Date(
                new Date().getTime() +
                  new Date().getTimezoneOffset() * 60 * 1000 +
                  order?.customer?.attributes?.timezone * 60 * 60 * 1000
              ).toLocaleTimeString()
            }}
          </div>
          <div :class="$style.order__block__modal__block__child">
            <div :class="$style.order__block__modal__block__child__title">Ученик</div>
            <div :class="$style.order__block__modal__block__child__name">
              {{
                order.student
                  ? `${
                      order.student.attributes.lastName
                        ? order.student.attributes.lastName
                        : 'Не заполнено'
                    } ${
                      order.student.attributes.firstName
                        ? order.student.attributes.firstName
                        : 'Не заполнено'
                    } ${
                      order.student.attributes.middleName
                        ? order.student.attributes.middleName
                        : ''
                    } [id: ${order.studentId}]`
                  : 'Не заполнено'
              }}
            </div>
            <div :class="$style.order__block__modal__block__child__info">
              <div :class="$style.order__block__modal__block__child__info__phone">
                {{
                  order.student
                    ? `${order.student.phone ? '+ ' + order.student.phone : 'Не заполнено'} `
                    : 'Не заполнено'
                }}
              </div>
              <a
                :class="$style.order__block__modal__block__child__info__tel"
                :href="
                  'tel:' +
                  (order.student ? `${order.student.phone ? order.student.phone : ''} ` : '')
                "
              >
                <img src="@/assets/icons/phone-call.svg" alt="phone call" />
              </a>

              <div
                :class="$style.order__block__modal__block__child__info__room"
                @click="createRoom(order.student.id)"
              >
                <img src="@/assets/icons/chat-write.svg" alt="chat write" />
              </div>
              <div
                :class="$style.order__block__modal__block__child__info__link"
                @click="goTo(order.student.id, 'parents')"
              >
                <img src="@/assets/icons/user.svg" alt="user" />
              </div>
            </div>
          </div>

          <div :class="$style.order__block__modal__block__pedagog">
            <div :class="$style.order__block__modal__block__pedagog__title">Родитель</div>
            <div :class="$style.order__block__modal__block__pedagog__name">
              {{
                order.customer
                  ? `${
                      order.customer.attributes.lastName
                        ? order.customer.attributes.lastName
                        : 'Не заполнено'
                    } ${
                      order.customer.attributes.firstName
                        ? order.customer.attributes.firstName
                        : 'Не заполнено'
                    } ${
                      order.customer.attributes.middleName
                        ? order.customer.attributes.middleName
                        : ''
                    } [id: ${order.customer.id}]`
                  : 'Не заполнено'
              }}
            </div>
            <div :class="$style.order__block__modal__block__pedagog__info">
              <div :class="$style.order__block__modal__block__pedagog__info__phone">
                {{
                  order.customer
                    ? `${order.customer.phone ? '+ ' + order.customer.phone : 'Не заполнено'} `
                    : 'Не заполнено'
                }}
              </div>
              <a
                :class="$style.order__block__modal__block__pedagog__info__tel"
                :href="
                  'tel:' +
                  (order.customer
                    ? `${order.customer.phone ? order.customer.phone : ''} `
                    : '')
                "
              >
                <img src="@/assets/icons/phone-call.svg" alt="phone call" />
              </a>

              <div
                :class="$style.order__block__modal__block__pedagog__info__room"
                @click="createRoom(order.customer.id)"
              >
                <img src="@/assets/icons/chat-write.svg" alt="chat write" />
              </div>
              <div
                :class="$style.order__block__modal__block__pedagog__info__link"
                @click="goTo(order.student.id, 'parents')"
              >
                <img src="@/assets/icons/user.svg" alt="user" />
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.order__block__modal__buttons">
          <!-- <div v-if="isClosedOrder">
            <Button
              :class="$style.order__block__modal__buttons__first"
              type="table"
              @click="goToRecord"
              >Запись занятия</Button
            >
          </div> -->

          <div v-if="canCancelOrder">
            <Button
              :class="$style.order__block__modal__buttons__first"
              type="table"
              @click="onCloseOrder"
              >Закрыть</Button
            >
          </div>

          <!-- <div v-if="order.teachingTypeId === 3 && order.customTypeId === 1">
            <Button
              :class="$style.order__block__modal__buttons__first"
              type="table"
              @click="onUpdateAfterConsultation"
              >Сохранить</Button
            >
          </div> -->
          <!-- <div
            v-else-if="
              order.teachingTypeId !== 3 &&
              order.customTypeId !== 1 &&
              [1, 2].includes(order.status.id)
            "
          >
            <Button
              :class="$style.order__block__modal__buttons__second"
              type="table"
              @click="onUpdate"
              >Сохранить</Button
            >
          </div> -->
        </div>
      </div>
    </div>

    <HomeworkDetails v-if="isClosedOrder" :order="order" />

    <div :class="$style.order__filter">
      <div :class="$style.order__filter__count">Скрыто {{ hiddenArray.length }}</div>
      <div :class="$style.order__filter__button" @click="doVisibled">Показать скрытых</div>
    </div>
    <div :class="$style.order__table">
      <div :class="$style.order__teacherSelection">
        <div
          v-for="pedagog in pedagogs"
          :key="pedagog.id"
          :class="[
            $style.order__teacherSelection__wrapper,
            {
              [$style.order__teacherSelection_inactive]: !isVerifiedPedagog(pedagog)
            }
          ]"
        >
          <div :class="$style.order__teacherSelection__item">
            <img
              :class="$style.order__teacherSelection__item__icon"
              src="@/assets/icons/eye.svg"
              alt="icon"
              @click="hidenTeacher(pedagog.id)"
            />
          </div>
          <div :class="$style.order__teacherSelection__item">
            {{
              pedagog.attributes.lastName +
              ' ' +
              pedagog.attributes.firstName +
              ' ' +
              pedagog.attributes.middleName
            }}
            <img
              src="@/assets/icons/user.svg"
              alt="user"
              @click="openModal(pedagog, 'teachers')"
            />
          </div>
          <div :class="$style.order__teacherSelection__item">
            <span
              :class="[
                pedagog.info.status == 'Свободен'
                  ? $style.order__teacherSelection__item_free
                  : pedagog.info.status == 'Не свободен'
                  ? $style.order__teacherSelection__item_unfree
                  : $style.order__teacherSelection__item_busy
              ]"
              >{{ pedagog.info.status }}</span
            >
          </div>

          <div :class="$style.order__teacherSelection__item">
            <Button
              v-if="
                order.statusId === orderStatuses.NEW &&
                order.teacherId === pedagog.id &&
                pedagog.info.status == 'Свободен'
              "
              :class="$style.order__teacherSelection__item__appoint"
              type="table"
              @click="setTeachers(pedagog.id)"
            >
              Подтвердить автораспределение
            </Button>

            <Button
              v-else-if="order.teacherId != pedagog.id && pedagog.info.status !== 'На занятии'"
              type="table"
              @click="setTeachers(pedagog.id)"
            >
              Назначить
            </Button>

            <Button
              v-else-if="order.teacherId == pedagog.id"
              :class="$style.order__teacherSelection__item__delete"
              type="table"
              @click="deleteTeachers(pedagog.id)"
            >
              Удалить
            </Button>
            <Button
              v-if="
                order.statusId === orderStatuses.NEW &&
                pedagog.info.status === 'Свободен' &&
                !getRefuses(pedagog.id)
              "
              :class="$style.order__teacherSelection__item__delete"
              type="table"
              @click="
                $modal.show('refuse' + pedagog.id, {
                  dynamic: true,
                  adaptive: true
                })
              "
            >
              Отказаться
            </Button>
          </div>

          <div :class="$style.order__teacherSelection__item">
            <div class="scroll">
              <div v-if="pedagog?.info" class="timeline" :style="{ '--range': range }">
                <div class="timeblock">
                  <div
                    v-for="(number, index) in numbers"
                    :key="index"
                    class="time"
                    style="display: flex; z-index: 17"
                    :style="{ '--left': timeScale(number) }"
                  >
                    {{ number }}:00
                  </div>
                </div>
                <div
                  class="ordertime"
                  :style="{
                    '--width': `${findDuration(order?.duration) / 16}rem`,
                    '--start': timeRelativeToRange(pedagog, datetime),
                    '--end': ['00:00:00', '00:00'].includes(cutSeconds(datetime))
                      ? 1440
                      : timeRelativeToRange(pedagog, datetime)
                  }"
                  :title="
                    cutSeconds(datetime) +
                    ' - ' +
                    cutSeconds(
                      moment(datetime)
                        .add(findDuration(order?.duration), 'minutes')
                        .format('YYYY-MM-DD HH:mm:ss')
                    )
                  "
                ></div>
                <div
                  v-if="
                    pedagog?.info?.schedule?.length > 0 && fondDate(pedagog?.info?.schedule)
                  "
                >
                  <div
                    v-for="(interval, index) in fondDate(pedagog?.info?.schedule).intervals"
                    :key="index"
                    class="ordertime-lightgreen"
                    :style="{
                      '--width': `${foundWidth(interval) / 16}rem`,
                      '--start': timeRelativeToRange(
                        pedagog,
                        moment(fondDate(pedagog?.info?.schedule).date).format('YYYY-MM-DD') +
                          ' ' +
                          interval?.start
                      ),
                      '--end': timeRelativeToRange(
                        pedagog,
                        moment(fondDate(pedagog?.info?.schedule).date).format('YYYY-MM-DD') +
                          ' ' +
                          interval?.end
                      )
                    }"
                    :title="getTitle(pedagog, interval)"
                  />
                  <div
                    v-if="
                      pedagog?.info?.schedule?.length > 0 && fondDate(pedagog?.info?.schedule)
                    "
                  >
                    <div
                      v-for="(interval, index) in fondDate(pedagog?.info?.schedule).intervals"
                      :key="index"
                    >
                      <div v-if="interval?.orders?.length > 0">
                        <div
                          v-for="(order, index) in interval.orders"
                          :key="index"
                          class="ordertime-green"
                          :style="{
                            '--width': `${
                              (order?.resultDuration
                                ? order?.resultDuration + 10
                                : order?.duration + 10) / 16
                            }rem`,
                            '--start': timeRelativeToRange(
                              pedagog,
                              moment(order?.datetime).format('YYYY-MM-DD HH:mm:ss')
                            )
                          }"
                          :title="getTitleForOrders(pedagog, order)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="$style.order__teacherSelection__wrapperLine">
          <div v-for="line in 5" :key="line" :class="$style.order__teacherSelection__line" />
        </div>
      </div>
    </div>
    <div v-if="order.changelogs.length > 0" :class="$style.logs">
      <div :class="$style.order__subtitle">
        <h2>Истории переносов</h2>
      </div>
      <div :class="$style.logs__header">
        <div :class="[$style.logs__header__item, $style.logs__header__item__border]">
          Кто перенес
        </div>
        <div :class="[$style.logs__header__item, $style.logs__header__item__border]">
          Дата изменения
        </div>
        <div :class="[$style.logs__header__item, $style.logs__header__item__border]">
          Старое время проведения занятия
        </div>
        <div :class="$style.logs__header__item">Новое время проведения занятия</div>
      </div>
      <div v-for="(item, index) in order.changelogs" :key="index" :class="$style.logs__body">
        <div :class="[$style.logs__header__item, $style.logs__header__item__border]">
          {{ item.employee?.middleName ? item.employee.middleName : '' }}
          {{ item.employee?.firstName ? item.employee.firstName : '' }}
          {{ item.employee?.lastName ? item.employee.lastName : '' }}
        </div>
        <div :class="[$style.logs__header__item, $style.logs__header__item__border]">
          {{ moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
        </div>
        <div :class="[$style.logs__header__item, $style.logs__header__item__border]">
          {{ moment(item.oldValue).format('YYYY-MM-DD HH:mm:ss') }}
        </div>
        <div :class="$style.logs__header__item">
          {{ moment(item.newValue).format('YYYY-MM-DD HH:mm:ss') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import Moment from 'moment'
import orderApi from '@/api/orders'
import orders from '@/api/orders'
import { timeToFloat } from '@/utils/calendar'

import { childrenAgeGroups, LessonStatus, TeachingType } from '@/application/constants'

import Button from '@/basic/Button'
import CustomTextarea from '@/basic/CustomTextarea'
import HomeworkDetails from '@/components/HomeworkDetails'
import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'

import 'vue2-datepicker/index.css'
import CustomInput from '@/basic/Input.vue'

export default {
  name: 'OrderDetail',
  components: {
    CustomInput,
    Button,
    CustomTextarea,
    DatePicker,
    HomeworkDetails
  },
  data() {
    return {
      lang: ru,
      pedagogs: [],
      selectReasonId: null,
      datetime: null,
      reasonComment: null,
      otherTextForPreschoolOrder: '',
      renderComponent: true,
      orderStatuses: LessonStatus,
      writtenMediaFile: null,
      oralMediaFile: null,
      getStudentInfo: '',
      hiddenArray: [],
      maxTime: 24,
      minTime: 0,
      logopedSubject: 'Логопедия',
      timezone: 3,
      numbers: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24
      ]
    }
  },
  watch: {
    $route(newId, oldId) {
      if (newId.params.id !== oldId.params.id) {
        this.forceRerender()
      }
    }
  },
  beforeDestroy() {
    this.setOrderDetails({})
  },
  sockets: {
    async 'server-notifications-send'({ data, type }) {
      console.log('server-notifications-send', data, type)
      const orderData = JSON.parse(data)
      try {
        const { success, result } = await orders.getOrder(orderData.orderId)
        if (success && result) {
          const orderDetails = result
          this.datetime = this.moment(orderDetails.datetime).format('YYYY-MM-DD HH:mm:ss')
          this.setOrderDetails(orderDetails)
        }
      } catch (e) {
        console.error(e)
      }
      if (type.id === 17 && orderData.orderId === this.$route.params.id) {
        try {
          if (orderData.orderId === +this.$route.params.id) {
            this.$store.dispatch('openModal', [
              'Alert',
              { title: 'Внимание', message: type.name }
            ])
          }
          this.forceRerender()
        } catch (e) {
          console.error(e)
        }
      } else {
        return
      }
    }
  },
  computed: {
    ...mapGetters({
      order: 'orderDetails',
      modalParams: 'modalParams',
      isAdministrator: 'isAdmin',
      isManager: 'isManager',
      isManagerKc: 'isManagerKc'
    }),
    moment() {
      return Moment
    },
    range() {
      return 1440
    },
    orderId() {
      return this.order?.id ?? this.$route.params.id
    },
    cancellationReasons() {
      return this.$store.getters['constants']?.ordersCancellationReasons ?? []
    },
    completingReasons() {
      return this.$store.getters['constants']?.ordersEarlyCompletingReasons ?? []
    },
    getDurations() {
      return this.$store.getters['constants']?.durations ?? []
    },
    isSchoolOrder() {
      return this.order.teachingTypeId === 1
    },
    isLogopedistOrder() {
      return this.order.teachingTypeId === 2
    },
    isPreSchoolOrder() {
      return this.order.teachingTypeId === 3
    },
    logopedisticAttributes() {
      return this.order?.group?.logopedisticAttributes ?? null
    },
    preschoolAttributes() {
      return this.order?.group?.preschoolAttributes ?? null
    },
    canCancelOrder() {
      return (
        (this.isAdministrator || this.isManagerKc) &&
        [LessonStatus.NEW, LessonStatus.APPOINTED].includes(this.order.statusId)
      )
    },
    pdfFileName() {
      return this.logopedisticAttributes?.writtenMediaFileKey?.split('/')[3] ?? ''
    },
    logopedistVerdict() {
      return this.logopedisticAttributes?.specialistComment ?? ''
    },
    orderDirection() {
      return this.order?.teachingType?.specializationName || 'Не заполнено'
    },
    childrenAgeGroupName() {
      return (
        childrenAgeGroups.find((e) => e.id === this.logopedisticAttributes?.childrenAgeGroupId)
          ?.name ?? null
      )
    },
    isClosedOrder() {
      return this.order.statusId === LessonStatus.CLOSED
    }
  },
  async created() {
    await this.fetchOrder()

    if (this.logopedisticAttributes?.oralMediaFileKey) {
      try {
        await this.getMediaFile('oral')
      } catch (error) {
        console.warn(error) // eslint-disable-line
      }
    }

    if (this.logopedisticAttributes?.writtenMediaFileKey) {
      try {
        await this.getMediaFile('written')
      } catch (error) {
        console.warn(error) // eslint-disable-line
      }
    }

    await this.fetchMatchingPedagogs()
    this.hiddenArray =
      JSON.parse(decodeURIComponent(this.$cookies.get(`${this.order.id}`))) ?? []
    if (this.hiddenArray && this.hiddenArray.length > 0) {
      this.pedagogs = this.pedagogs.filter((el) => !this.hiddenArray.includes(el.id))
    }
    this.otherTextForPreschoolOrder =
      this.order?.group?.preschoolAttributes?.programsContents?.find((p) => p.customName)
        ?.customName ?? ''
    this.getStudentInfo = this.order?.attributes?.studentAge
      ? `${this.order?.attributes?.studentAge} лет`
      : `${this.order?.attributes?.studentGrade} класс`
  },
  methods: {
    ...mapMutations(['setOrderDetails', 'setChat']),
    forceRerender: async function () {
      // Remove my-component from the DOM
      await this.fetchOrder()
    },
    findDuration(obj) {
      return this.getDurations.find((el) => el.id === obj.id).duration
    },
    async doVisibled() {
      await this.fetchMatchingPedagogs()
      this.hiddenArray = []
      this.$cookies.remove(`${this.order.id}`)
    },
    setupData(array) {
      return array.map((e) => {
        return {
          ...e,
          orders: array.map((el) => {
            return {
              start: Moment(this.refactoringTime(el.start)).add(1, 'h').format('HH:mm:ss'),
              end: Moment(this.refactoringTime(el.end)).add(1, 'h').format('HH:mm:ss')
            }
          })
        }
      })
    },
    cutSeconds(value) {
      const time = Moment(value).utcOffset(this.timezone).format('HH:mm')
      const [hours, minutes] = time.split(/[.:]/)

      if (!hours || !minutes) {
        return 'Н/A'
      }
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
    },
    foundWidth(array) {
      const start = this.moment(array.start, 'HH:mm:ss')
      const end = this.moment(array.end, 'HH:mm:ss')
      const diffInMilliseconds = Math.abs((end - start) / 1000)
      return diffInMilliseconds / 60
    },
    fondDate(dates) {
      const arrayDates = dates.map((el) => {
        return {
          ...el,
          date: Moment(el.date).format('YYYY-MM-DD HH:mm:ss')
        }
      })
      const targetDate =
        this.datetime !== 'Invalid date' ? Moment(this.datetime) : Moment(new Date())
      return arrayDates.find((date) => {
        const currentDate = Moment(date.date)
        return (
          currentDate.year() === targetDate.year() &&
          currentDate.month() === targetDate.month() &&
          currentDate.date() === targetDate.date()
        )
      })
    },
    getTitle(p, arr) {
      const pedagogTimezone = p?.attributes?.timezone
      const diff = this.timezone === pedagogTimezone ? 0 : this.timezone - pedagogTimezone
      const start = Moment(arr.start, 'HH:mm:ss')
      const end = Moment(arr.end, 'HH:mm:ss')
      return (
        Moment(start).add(diff, 'h').format('HH:mm:ss') +
        ' - ' +
        Moment(end).add(diff, 'h').format('HH:mm:ss')
      )
    },
    getTitleForOrders(p, date) {
      const pedagogTimezone = p?.attributes?.timezone
      const diff = this.timezone === pedagogTimezone ? 0 : this.timezone - pedagogTimezone
      return (
        Moment(date?.datetime).add(diff, 'h').format('HH:mm:ss') +
        ' - ' +
        Moment(date?.datetime)
          .add(diff, 'h')
          .add(
            date?.resultDuration ? date?.resultDuration + 10 : date?.duration + 10,
            'minutes'
          )
          .format('HH:mm:ss')
      )
    },
    timeRelativeToRange(p, time) {
      return Math.min(
        1440,
        Math.max(
          0,
          timeToFloat(Moment(time).utcOffset(this.timezone).format('HH:mm')) - this.minTime
        )
      )
    },
    timeScale(number) {
      return Math.min(1440, Math.max(0, number - this.minTime))
    },
    hidenTeacher(id) {
      this.hiddenArray.push(id)
      this.pedagogs = this.pedagogs.filter((el) => el.id !== id)
      const str = JSON.stringify(this.hiddenArray)
      this.$cookies.set(`${this.order.id}`, encodeURIComponent(str))
    },
    subString(str) {
      return str && str.length > 25 ? str.slice(0, 29) + '...' : str
    },
    downloadFile() {
      const link = document.createElement('a')
      link.href = this.writtenMediaFile
      link.download = this.pdfFileName
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    getDuration() {
      const seconds = Moment(this.order?.chat?.end).diff(Moment(this.order?.chat?.start), 's')
      return Moment.utc(seconds * 1000).format('HH:mm:ss')
    },
    getRefuses(id) {
      const refuse = this.pedagogs
        .find((e) => e.id === id)
        .distributionDeclines?.find((e) => e.orderId === this.order.id)
      return refuse ? refuse?.text || refuse?.type?.name : null
    },
    async sendRefuse(userId) {
      if (
        (this.reasonComment?.length < 30 && this.selectReasonId === 4) ||
        !this.selectReasonId
      ) {
        this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Внимание',
            message: 'Выберите причину или напишите свой вариант(не менее 30 символов)!'
          }
        ])
        return
      }

      const { data } = await orderApi.declineOrder({
        id: this.order.id,
        typeId: this.selectReasonId,
        text: this.reasonComment,
        userId
      })

      if (data && data.success) {
        window.location.reload()
      }
    },
    async fetchOrder() {
      try {
        const { success, result } = await orders.getOrder(this.orderId)

        if (success && result) {
          const orderDetails = result

          this.datetime = this.moment(orderDetails.datetime).format('YYYY-MM-DD HH:mm:ss')

          this.setOrderDetails(orderDetails)
        }
      } catch (error) {
        console.warn(error)
      }
    },
    refactoringTime(timeString) {
      const [hours, minutes, seconds] = timeString.split(':')
      const time = new Date()
      time.setHours(hours)
      time.setMinutes(minutes)
      time.setSeconds(seconds)
      return time
    },
    async fetchMatchingPedagogs() {
      if (this.order?.teachingTypeId === TeachingType.TEACHER) {
        try {
          const { data } = await orders.getTeachersPedagogs(this.orderId)

          if (data?.success && data?.result?.count > 0) {
            this.pedagogs = data.result.rows
          }
        } catch (error) {
          console.warn(error)
        }
      } else if (this.order?.teachingTypeId === TeachingType.LOGOPEDIST) {
        try {
          const { data } = await orders.getLogopedists(this.orderId)

          if (data?.success && data?.result?.count > 0) {
            this.pedagogs = data.result.rows
          }
        } catch (error) {
          console.warn(error)
        }
      } else if (this.order?.teachingTypeId === TeachingType.PRESCHOOL_TEACHER) {
        try {
          const { data } = await orders.getPreschoolTeachers(this.orderId)

          if (data?.success && data?.result?.count > 0) {
            this.pedagogs = data.result.rows
          }
        } catch (error) {
          console.warn(error)
        }
      }
    },
    async setOrder(order) {
      this.$router.push(`/orders/${order.id}`)
      await this.fetchOrder()
      this.$forceUpdate()
    },
    goTo(id, key) {
      this.$router.push(`/${key}/${id}`)
    },
    disabledDates(date) {
      return this.moment(date).isBefore(Moment().format('YYYY-MM-DD'))
    },
    createRoom(event) {
      this.$socket.emit(
        'dashboard-chats-init',
        {
          userId: event
        },
        (data) => {
          //this.$router.push(`/chats/${data.result.id}`)
          this.chatId = data.result.id
          this.$socket.emit('dashboard-chats-enter', { chatId: this.chatId }, (data) => {
            if (data && data.success) {
              this.setChat(data.result)
              this.$router.push(`/chats/${this.chatId}`)
              //this.$router.push({ name: 'newChat', params: { myId: this.chatId } })
            }
          })
        }
      )
    },
    openModal(pedagog) {
      this.$store.dispatch('openModal', [
        'TeacherModal',
        { pedagog: pedagog, orderType: this.order.teachingTypeId }
      ])
    },
    async setTeachers(id) {
      try {
        const { data } = await orders.setTeachers(this.order.id, id)
        if (data && data.success) {
          this.order.teacherId = id
          this.fetchMatchingPedagogs()
          await this.fetchOrder()
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Ошибка', message: data.result.message }
          ])
        }
      } catch (e) {
        await this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка', message: e.message }
        ])
      }
    },
    async setMessage(id) {
      await orders.setMessage(id)
      alert('Уведомление отправлено')
    },
    async deleteTeachers(id) {
      try {
        await orders.deleteTeachers(this.order.id, id)
        this.order.teacherId = null
        this.fetchMatchingPedagogs()
      } catch (e) {
        alert('У этого заказа невозможно удалить назначение')
      }
    },
    async onUpdateAfterConsultation() {
      try {
        const localData = {
          id: this.orderId
        }

        if (this.datetime === 'Invalid date' || this.datetime === null) {
          delete localData.datetime
        } else {
          localData.datetime = this.moment(this.datetime).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        }

        if (this.otherTextForPreschoolOrder.length > 0) {
          localData.text = this.otherTextForPreschoolOrder
        }

        await orderApi.updateAfterConsultation(localData)
      } catch (e) {
        alert(e)
      }
    },
    async onUpdate() {
      try {
        const localData = {
          id: Number(this.orderId)
        }
        const currentDateTime = this.moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')

        if (this.datetime === 'Invalid date' || this.datetime === null) {
          delete localData.datetime
        } else {
          localData.datetime = this.moment(this.datetime).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        }
        if (this.moment(localData.datetime).isSameOrAfter(this.moment(currentDateTime))) {
          const { data } = await orderApi.updateOrder(localData)
          const orderDetails = data.result
          this.datetime = this.moment(orderDetails.datetime).format('YYYY-MM-DD HH:mm:ss')
          this.setOrderDetails(orderDetails)

          if (data?.success) {
            this.fetchMatchingPedagogs()
          }
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message: 'Проверьте правильность выбранной даты и времени!'
            }
          ])
        }
      } catch (e) {
        alert(e)
      }
    },
    async onCloseOrder() {
      const ask = () =>
        this.$store.dispatch('openModal', [
          'CancelOrder',
          {
            title: 'Закрыть этот заказ?',
            message: 'Укажите причину закрытия заказа'
          }
        ])

      if (!(await ask())) {
        return
      }

      try {
        const { data } = await orderApi.cancelOrderById({
          orderId: this.order.id,
          cancellationReasonId: this.modalParams?.selectedManualStatusId
        })

        if (data?.success) {
          this.$store.commit('setParams', null)

          await this.fetchOrder()

          await this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Закрытие заказа', message: 'Заказ успешно закрыт!' }
          ])
        }
      } catch (error) {
        console.warn(error)

        await this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Закрытие заказа', message: 'Ошибка закрытия заказа!' }
        ])
      }
    },
    async getMediaFile(type) {
      const response = await orderApi.getFileForLogopedistSession(this.order.groupId, type)

      if (response && response.status === 200) {
        if (type === 'written') {
          if (this.logopedisticAttributes?.writtenMediaFileKey?.includes('.pdf')) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            this.writtenMediaFile = url
          } else {
            const buffer = Buffer.from(response.data)
            const base64String = buffer.toString('base64')
            this.writtenMediaFile = `data:image/jpg;base64,${base64String}`
          }
        } else {
          const blob = new Blob([response.data])
          this.oralMediaFile = window.URL.createObjectURL(blob)
        }
      }
    },
    isVerifiedPedagog(pedagog) {
      return (
        pedagog?.teacher?.isVerified ||
        pedagog?.logopedist?.isVerified ||
        pedagog?.preschoolTeachers?.isVerified
      )
    },
    async goToRecord() {
      try {
        const { data } = await orderApi.goToRecord(this.orderId)
        const result = data?.result[0] ?? null

        if (result?.url) {
          window.open(result.url, '_blank')
        } else if (result?.state === 'processing' || result) {
          await this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Запись занятия', message: 'Запись обрабатывается.' }
          ])
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Запись занятия', message: 'Запись не найдена.' }
          ])
        }
      } catch (e) {
        console.warn(e)
        await this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Запись занятия', message: 'Ошибка получения данных с сервера!' }
        ])
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/breakpoints';
.scroll {
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  height: 2.5rem;
  width: 90rem;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-color: rgba(107, 83, 221, 1) transparent !important;
  scrollbar-width: thin !important;
  &::-webkit-scrollbar {
    width: 14.804rem !important;
    height: 0.313rem !important;
    border-radius: 0.125rem !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(107, 83, 221, 1) !important;
  }

  &::-webkit-scrollbar-track-piece:end {
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-button {
    display: none !important;
  }
}
.timeline {
  --period-hour-width: calc(100% / var(--range));
  --body-height: #{3rem};
  --body-width: #{90rem};
  height: var(--body-height);
  width: var(--body-width);
  border-radius: 1.25rem;
  background-color: $white;
  position: relative;
}
.timeblock {
  display: flex;
  width: var(--body-width);
  z-index: 22;
  position: absolute;
  top: 38%;
  transform: translateY(-50%);
  color: rgba(1, 1, 1, 0.5);
  font-size: 0.75rem;
  font-weight: 300;
  user-select: none;
}
.minTime,
.maxTime {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(151, 150, 150, 1);
  font-size: 0.75rem;
  font-weight: 300;
  user-select: none;
}
.minTime {
  left: 0.25rem;
}
.maxTime {
  right: 0.25rem;
}
.time {
  position: absolute;
  left: calc((var(--left) * 60) * calc(100% / var(--range)));
  align-items: center;
  top: 0;
  z-index: 6;
  width: max-content;
  bottom: 0;
}
.ordertime {
  height: 2.6rem;
  border-left: 0.063rem dashed rgba(107, 83, 221, 1);
  background: rgba(107, 83, 221, 0.5);
  width: var(--width);
  cursor: pointer;
  --lesson-hour-width: calc(100% / ((var(--end) * 60) - (var(--start) * 60)));
  display: flex;
  align-items: center;
  left: calc((var(--start) * 60) * calc(100% / var(--range)));
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: 0;
  &-lightgreen {
    background: rgba(215, 238, 213, 1);
    border-right: rgba(215, 238, 213, 1);
    margin: 0.125rem 0;
    height: 2.2rem;
    width: var(--width);
    cursor: pointer;
    border-radius: 0.25rem;
    --lesson-hour-width: calc(100% / ((var(--end) * 60) - (var(--start) * 60)));
    display: flex;
    align-items: center;
    left: calc((var(--start) * 60) * var(--period-hour-width));
    position: absolute;
    z-index: 1;
    top: -0.188rem;
    bottom: 0;
  }
  &-green {
    background: rgba(156, 212, 150, 1);
    border-right: rgba(156, 212, 150, 1);
    margin: 0.125rem 0;
    height: 2.2rem;
    width: var(--width);
    cursor: pointer;
    border-radius: 0.25rem;
    --lesson-hour-width: calc(100% / ((var(--end) * 60) - (var(--start) * 60)));
    display: flex;
    align-items: center;
    left: calc((var(--start) * 60) * var(--period-hour-width));
    position: absolute;
    z-index: 2;
    top: -0.188rem;
    bottom: 0;
  }
}
</style>
<style lang="scss" module>
@import '@/assets/styles/colors.scss';

table {
  border-collapse: collapse;
  margin-bottom: 20px;
}
.logs {
  margin-bottom: 2rem;
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 0.063rem solid #bebebe;
    border-bottom: none;
    font-size: 0.875rem;
    font-weight: 500;
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25rem;
      height: 3rem;
      &__border {
        border-right: 0.063rem solid #bebebe;
      }
    }
  }
  &__body {
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 0.063rem solid #bebebe;
  }
}
.order {
  &__title {
    display: flex;
    margin-bottom: 1.375rem;
    &__header {
      display: flex;
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.465rem;
      margin-right: 2.438rem;
      align-items: center;
      height: 1.375rem;
    }
    &__status {
      padding: 0.313rem 0.5rem;
      display: flex;
      margin-right: 2.438rem;
      border-radius: 0.313rem;
      background-color: rgba(188, 188, 188, 1);
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 0.879rem;
      align-items: center;
      justify-content: center;
    }
    &__trial {
      padding: 0.313rem 0.5rem;
      display: flex;
      margin-right: 2.438rem;
      border-radius: 0.313rem;
      background-color: rgba(188, 188, 188, 1);
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 0.879rem;
      align-items: center;
      justify-content: center;
    }
    &__name {
      margin-right: 2.438rem;
      padding: 0.313rem 0.5rem;
      display: flex;
      background-color: rgba(87, 103, 187, 0.3);
      border-radius: 0.313rem;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 0.879rem;
      align-items: center;
      justify-content: center;
    }
  }
  &__block {
    display: flex;
    margin-bottom: 3.673rem;
    &__content {
      width: 80%;
      &__title {
        display: flex;
        flex-direction: row;
        margin-bottom: 2.188rem;
        &__block {
          display: flex;
          flex-direction: row;
          height: 0.75rem;
          &__text {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 0.75rem;
            line-height: 0.733rem;
            color: rgba(160, 159, 159, 1);
            margin-right: 1.375rem;
          }
          &__input {
            display: flex;
            justify-content: space-between;
            width: 5.625rem;
            &__text {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 0.688rem;
              line-height: 0.659rem;
              color: rgba(160, 159, 159, 1);
              &__mr {
                margin-right: 0.25rem;
              }
            }
            &__mr {
              margin-right: 2.133rem;
            }
          }
        }
      }
      &__block {
        display: flex;
        flex-direction: column;
        &__first {
          display: flex;
          flex-direction: row;
          margin-bottom: 1.7rem;
          &__datetime {
            display: flex;
            margin-right: 1.073rem;
            flex-direction: column;
            &__text {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 700;
              font-size: 0.75rem;
              line-height: 0.733rem;
              color: rgba(42, 43, 42, 1);
              margin-bottom: 0.489rem;
            }
            &__input {
              width: 11.125rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.026rem;
              color: rgba(188, 188, 188, 1);
              input {
                padding: 0 !important;
                cursor: default !important;
              }
            }
          }
          &__duration {
            display: flex;
            flex-direction: column;
            margin-right: 1.375rem;
            &__text {
              width: 11.125rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 700;
              font-size: 0.75rem;
              line-height: 0.733rem;
              color: rgba(42, 43, 42, 1);
              margin-bottom: 0.489rem;
            }
            &__input {
              cursor: default;
              width: 11.125rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 0.375rem 0.625rem;
              border-radius: 0.375rem;
              border: 0.063rem solid rgba(188, 188, 188, 1);
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.026rem;
              color: rgba(188, 188, 188, 1);
              input {
                padding: 0 !important;
                cursor: default !important;
              }
            }
          }
          &__class {
            display: flex;
            flex-direction: column;
            &__text {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 700;
              font-size: 0.75rem;
              line-height: 0.733rem;
              color: rgba(42, 43, 42, 1);
              margin-bottom: 0.489rem;
            }
            &__input {
              cursor: default;
              width: 11.125rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 0.375rem 0.625rem;
              border-radius: 0.375rem;
              border: 0.063rem solid rgba(188, 188, 188, 1);
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.026rem;
              color: rgba(188, 188, 188, 1);
              input {
                padding: 0 !important;
                cursor: default !important;
              }
            }
          }
        }
        &__second {
          display: flex;
          flex-direction: row;
          margin-bottom: 1.438rem;
          &__subjects {
            display: flex;
            flex-direction: column;
            margin-right: 1.438rem;
            &__text {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 700;
              font-size: 0.75rem;
              line-height: 0.733rem;
              color: rgba(42, 43, 42, 1);
              margin-bottom: 0.489rem;
            }
            &__input {
              cursor: default;
              width: 11.125rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 0.375rem 0.625rem;
              border-radius: 0.375rem;
              border: 0.063rem solid rgba(188, 188, 188, 1);
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.026rem;
              color: rgba(188, 188, 188, 1);
              input {
                padding: 0 !important;
                cursor: default !important;
              }
            }
          }
          &__status {
            display: flex;
            flex-direction: column;
            &__text {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 700;
              font-size: 0.75rem;
              line-height: 0.733rem;
              color: rgba(42, 43, 42, 1);
              margin-bottom: 0.489rem;
            }
            &__input {
              cursor: default;
              width: 11.125rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 0.375rem 0.625rem;
              border-radius: 0.375rem;
              border: 0.063rem solid rgba(188, 188, 188, 1);
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.026rem;
              color: rgba(188, 188, 188, 1);
              input {
                padding: 0 !important;
                cursor: default !important;
              }
            }
          }
        }
        &__three {
          display: flex;
          flex-direction: row;
          margin-bottom: 1.438rem;
          &__subjects {
            display: flex;
            flex-direction: column;
            margin-right: 1.438rem;
            &__text {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 700;
              font-size: 0.75rem;
              line-height: 0.733rem;
              color: rgba(42, 43, 42, 1);
              margin-bottom: 0.489rem;
            }
            &__block {
              border: 1px solid rgba(188, 188, 188, 1);
              border-radius: 0.375rem;
              padding: 0.375rem 0.625rem;
              width: 18.75rem;
              margin-right: 1rem;
              color: rgba(188, 188, 188, 1);
              div {
                margin-bottom: 0.5rem;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        &__comment {
          display: flex;
          flex-direction: column;
          &__block {
            &:last-child {
              margin-top: 1rem;
            }
            display: flex;
            flex-direction: column;
            &__text {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 700;
              font-size: 0.75rem;
              line-height: 0.733rem;
              color: rgba(42, 43, 42, 1);
              margin-bottom: 0.489rem;
            }
            &__input {
              width: 72.125rem;
              height: 4.438rem;
              display: flex;
              border: 0.063rem solid rgba(217, 217, 217, 1);
              color: rgba(0, 0, 0, 1);
              font-weight: 400;
              font-size: 0.75rem;
              line-height: 0.879rem;
              box-shadow: none;
              cursor: default;
              input {
                padding: 0 !important;
                cursor: default !important;
              }
            }
          }
        }
      }
    }
    &__modal {
      display: flex;
      flex-direction: column;
      &__block {
        padding: 1rem;
        border: 1px solid rgba(218, 218, 218, 1);
        border-radius: 0.375rem;
        height: 14.753rem;
        width: 18.5rem;
        margin-bottom: 1.372rem;
        &__time {
          display: flex;
          font-weight: 400;
          font-size: 0.688rem;
          line-height: 0.733rem;
          color: rgba(42, 43, 42, 1);
          margin-bottom: 0.938rem;
          align-items: center;
        }
        &__child {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.938rem;
          &__title {
            display: flex;
            font-weight: 700;
            font-size: 0.75rem;
            line-height: 0.733rem;
            color: rgba(42, 43, 42, 1);
            height: 1.375rem;
            margin-bottom: 0.25rem;
          }
          &__name {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 0.813rem;
            line-height: 0.879rem;
            height: 1.375rem;
            margin-bottom: 0.5rem;
          }
          &__info {
            display: flex;
            flex-direction: row;
            align-items: center;
            &__phone {
              display: flex;
              font-weight: 400;
              font-size: 0.75rem;
              line-height: 0.879rem;
              color: rgba(42, 43, 42, 1);
              height: 1.25rem;
              margin-right: 0.438rem;
              align-items: center;
            }
            &__tel {
              display: flex;
              align-items: center;
              height: 1.25rem;
              width: 1.25rem;
              margin-right: 0.438rem;
            }
            &__room {
              display: flex;
              align-items: center;
              height: 1.25rem;
              width: 1.25rem;
              margin-right: 0.438rem;
              cursor: pointer;
            }
            &__link {
              display: flex;
              align-items: center;
              height: 1.25rem;
              width: 1.25rem;
              cursor: pointer;
            }
          }
        }
        &__pedagog {
          &__title {
            display: flex;
            font-weight: 700;
            font-size: 0.75rem;
            line-height: 0.733rem;
            color: rgba(42, 43, 42, 1);
            height: 1.375rem;
            margin-bottom: 0.25rem;
          }
          &__name {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 0.813rem;
            line-height: 0.879rem;
            height: 1.375rem;
            margin-bottom: 0.5rem;
          }
          &__info {
            display: flex;
            flex-direction: row;
            align-items: center;
            &__phone {
              display: flex;
              font-weight: 400;
              font-size: 0.75rem;
              line-height: 0.879rem;
              color: rgba(42, 43, 42, 1);
              height: 1.25rem;
              margin-right: 0.438rem;
              align-items: center;
            }
            &__tel {
              display: flex;
              align-items: center;
              height: 1.25rem;
              width: 1.25rem;
              margin-right: 0.438rem;
            }
            &__room {
              display: flex;
              align-items: center;
              height: 1.25rem;
              width: 1.25rem;
              margin-right: 0.438rem;
              cursor: pointer;
            }
            &__link {
              display: flex;
              align-items: center;
              height: 1.25rem;
              width: 1.25rem;
              cursor: pointer;
            }
          }
        }
      }
      &__buttons {
        display: flex;
        justify-content: flex-end;
        &__first {
          width: max-content !important;
          height: 1.875rem !important;
          padding: 0.438rem 1.438rem 0.438rem 1.438rem !important;
          margin-right: 1rem !important;
        }
        &__second {
          width: 7rem !important;
          height: 1.875rem !important;
          padding: 0.438rem 1.438rem 0.438rem 1.438rem !important;
        }
      }
    }
  }
  &__input {
    width: 15rem;
    margin: 0 4rem 0 0;
  }
  &__content {
    width: 80%;
    display: flex;
    &_right {
      width: 314px;
      height: 225px;
      border: 1px solid #dadada;
      border-radius: 5px;
      padding: 11px 15px;
      a {
        position: relative;
        top: 5px;
        margin-right: 5px;
      }
      &_button {
        padding: 0px;
        position: relative;
        top: 5px;
        cursor: pointer;
      }
      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        padding: 5px;
      }
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        padding: 5px;
      }
    }
    &_wrap {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
    }
    &_infonew {
      position: relative;
      background: #c0c0c0;
      border-radius: 5px;
      padding: 3px 10px;
      height: 26px;
      margin: 0 1rem 1rem 0;
      span {
        color: #2a2b2a;
      }
      &_yellow {
        background: #feecc2;
      }
    }
    &_info {
      position: relative;
      min-width: 275px;
      width: auto;
      margin-right: 10px;
      margin-bottom: 15px;

      p {
        position: absolute;
        top: -8px;
        left: 5px;
        background: #fff;
        font-size: 12px;
      }

      div {
        border: 1px solid black;
        padding: 5px;
      }

      &_date {
        div {
          border: none;
        }
        border: 1px solid black;
        i {
          right: 15px;
        }
      }

      &__files {
        display: flex;
        flex-direction: column;

        &__text {
          width: 100%;
          margin: 0 0 0.5rem;
          font-size: 12px;
        }

        &__written {
          width: 60%;

          &__wrapper {
            width: 100%;
          }

          &__link {
            margin: 0 0 0.25rem;
            font-weight: 300;
            font-size: 0.75rem;
            color: $bondi-beach;
            transition: all 0.3s ease;

            &:hover {
              color: $twitter-color;
            }
          }
        }

        &__video {
          width: 60%;
          background: $black;
        }
      }
    }
    &__attributions {
      position: relative;
      min-width: 275px;
      width: auto;
      margin-right: 10px;
      margin-bottom: 15px;

      &__title {
        position: absolute;
        top: -8px;
        left: 5px;
        background: #fff;
        font-size: 12px;
      }

      &__item {
        border: 1px solid black;
        div {
          padding: 0.313rem;
          border-bottom: 1px solid black;

          &:last-child {
            border: none;
          }
        }
      }
    }
    &__other {
      width: 100%;
      margin-right: 10px;
      margin-bottom: 15px;
    }
  }
  &__form {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 26.25rem;
    margin: 0 1.25rem 1.25rem 0;
  }

  &__flex {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
    button {
      padding: 10px 15px;
      max-width: 300px;
      height: 36px;
    }
  }

  &__subtitle {
    margin: 1rem 0;
    font-size: 0.95rem;
    display: flex;
    justify-content: space-between;
    button {
      padding: 10px 15px;
      max-width: 300px;
      height: 36px;
    }
  }

  &__table {
    width: 100%;
    height: 27rem;
    overflow-y: auto;
    margin: 0 0 1rem;
    border: 1px solid $table-border;

    .header {
      display: flex;
      justify-content: space-between;
      height: 2.5rem;
      border-bottom: 1px solid $table-border;

      &__column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 1.1rem;
        font-weight: 500;

        &:first-child {
          width: 6%;
        }

        &:nth-child(2) {
          width: 15%;
        }

        &:nth-child(3) {
          width: 12%;
        }

        &:nth-child(4) {
          width: 15%;
        }

        &:nth-child(n + 5) {
          width: 20%;
        }

        &:nth-child(n + 6) {
          width: 18%;
        }

        &:nth-child(n + 7) {
          width: 14%;
        }

        &:last-child {
          border: none;
        }
      }
    }

    .order__teacherSelection {
      position: relative;
      display: flex;
      flex-direction: column;
      height: max-content;

      &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3.2rem;
        border-bottom: 1px solid $table-border;
      }

      &_inactive {
        background: $transparent-gray;
      }

      &__item {
        display: flex;
        align-items: center;
        padding: 0.1rem 0.5rem;
        font-size: 0.875rem;
        font-weight: 500;
        justify-content: space-between;
        &__delete {
          background: #ff3131 !important;
        }
        &__appoint {
          width: auto !important;
          background: $ferm-crayola !important;
        }
        button {
          width: 89px;
          height: 24px;
          background: #a09f9f;
          border-radius: 5px;
          color: #fff;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
        }
        &_free {
          background: rgba(97, 187, 87, 0.5);
          color: #fff;
          padding: 5px;
          border-radius: 15px;
          width: 100px;
          text-align: center;
        }
        &_unfree {
          background: rgba(217, 217, 217, 0.5);
          color: #2a2b2a;
          padding: 5px;
          border-radius: 15px;
          width: 100px;
          text-align: center;
        }
        &_busy {
          background: rgba(239, 73, 60, 0.5);
          color: #fff;
          padding: 5px;
          border-radius: 15px;
          width: 100px;
          text-align: center;
        }

        &__buttom {
          font-size: 1rem;
        }
        &:first-child {
          width: 3.85%;
          margin: 0 auto;
          justify-content: center;
          img {
            cursor: pointer;
          }
        }

        &:nth-child(2) {
          width: 18.97%;
          img {
            cursor: pointer;
          }
        }

        &:nth-child(3) {
          width: 9.9%;
          justify-content: center;
        }

        &:nth-child(4) {
          width: 9.44%;
          justify-content: center;
        }

        &:nth-child(n + 5) {
          width: 57.84%;
          padding-left: 0;
          padding-right: 0;
        }

        &:last-child {
          border: none;
        }

        &__icon {
          width: 0.9rem;
          height: 0.9rem;
        }
      }

      &__wrapperLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &__line {
        border-right: 1px solid $table-border;

        &:first-child {
          width: 3.85%;
        }

        &:nth-child(2) {
          width: 18.97%;
        }

        &:nth-child(3) {
          width: 9.9%;
        }

        &:nth-child(4) {
          width: 9.44%;
        }

        &:nth-child(n + 5) {
          width: 57.84%;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }

  &__position_button {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    width: 26.25rem;
    margin: 3.375rem 0 0;
  }
  &__refuse {
    display: flex;
    flex-direction: column;
    p {
      text-align: right;
      color: red;
    }
    &__list {
      display: flex;
      flex-direction: column;
      margin: 1rem 0.5rem;
      font-size: 0.875rem;
      div {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
      }
      &_text {
        margin: 1rem 0.5rem;
      }
      &_textarea {
        margin: 0 0.5rem;
        height: 10rem;
      }
      &_btn {
        background: $ferm-crayola !important;
        margin: 0 0 0 auto !important;
      }
    }
  }
  &__filter {
    display: flex;
    margin-bottom: 1.125rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.026rem;
    color: rgba(0, 0, 0, 1);
    &__count {
      display: flex;
      margin-right: 1rem;
    }
    &__button {
      cursor: pointer;
    }
  }
}
</style>
